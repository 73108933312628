import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const GTM_ID =
  publicRuntimeConfig?.NEXT_PUBLIC_GOOGLE_GTM_TRACKING_ID || '';
export const isProduction =
  publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT === 'prod';
export const isStaging =
  publicRuntimeConfig?.NEXT_PUBLIC_ENVIRONMENT === 'staging';
export const KEYCLOAK_URL =
  publicRuntimeConfig?.NEXT_PUBLIC_KEYCLOAK_URL || '';
export const ADMIN_URL =
  publicRuntimeConfig?.NEXT_PUBLIC_ADMIN_URL || '';
export const API_URL = publicRuntimeConfig?.NEXT_PUBLIC_API_URL || '';
export const MUI_LICENSE_KEY =
  publicRuntimeConfig?.NEXT_PUBLIC_MUI_LICENSE_KEY || '';
export const CDN_URL = publicRuntimeConfig?.NEXT_PUBLIC_CDN_URL || '';
